import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { ExpandMore as IconExpandMore } from "@material-ui/icons";
import DialogWrapper from "../common/DialogWrapper";
import FlexCol from "../common/FlexCol";
import { useState } from "react";

const styles = theme => ({
  exportList: { marginTop: theme.spacing(3) },
  scrollList: {
    maxHeight: 300,
    overflowY: "auto",
  },
  invalidFilesContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

function FilesExportDialog({ handleExport, close, selection, classes, open, files }) {
  const [fileType, setFileType] = useState("csv");
  const handleChange = e => {
    setFileType(e.target.value);
  };

  function isFileUnsupportedForVRL(file) {
    const matchedFile = files.find(f => f.name == file);
    return (
      matchedFile?.rxLogStatus?.parquet === "unsupported" ||
      matchedFile?.rxLogProperties?.receiverFwVersion?.major >= 6 // FW >= 6 means Gen 2 (Includes NexTrak)
    );
  }
  // files to be exported
  const exportFiles =
    fileType === "vrl"
      ? selection.filter(file => file.endsWith(".vdat") && !isFileUnsupportedForVRL(file))
      : selection;
  // files cannot be exported, csv or vrl files cannot be exported as vrl files
  const invalidFiles = fileType === "vrl" ? selection.filter(file => !file.endsWith(".vdat")) : [];

  // vdat from HR2,3 or gen 2 not supported:
  const invalidFilesUnsupported =
    fileType === "vrl" ? selection.filter(file => isFileUnsupportedForVRL(file)) : [];

  return (
    <DialogWrapper
      title="Export Files"
      open={open}
      okAction={() => handleExport(fileType, exportFiles)}
      okButtonContent="Download"
      okDisabled={exportFiles.length == 0}
      cancelAction={close}
      maxWidth="xs"
    >
      <FlexCol paddingLevel={1}>
        <Typography variant="body2">Export Format</Typography>
        <RadioGroup row value={fileType} onChange={handleChange}>
          <Tooltip title="Convert files to Fathom CSV">
            <FormControlLabel value="csv" control={<Radio size="small" />} label="Fathom CSV" />
          </Tooltip>
          <Tooltip title="Convert VDAT files to VUE compatible VRLs">
            <FormControlLabel
              value="vrl"
              control={<Radio size="small" />}
              label="VUE-compatible VRL"
            />
          </Tooltip>
        </RadioGroup>
        {exportFiles.length > 0 && (
          <div className={classes.exportList}>
            <Typography variant="body1">
              The file{exportFiles.length > 1 ? "s" : ""} below will be exported to{" "}
              {fileType.toUpperCase()} format:
            </Typography>
            <div className={classes.scrollList}>
              <ul>
                {exportFiles.map(filename => (
                  <li key={filename}>{filename}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className={classes.invalidFilesContainer}>
          {invalidFiles.length > 0 && (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<IconExpandMore fontSize="small" />}>
                <div>
                  {invalidFiles.length} file
                  {invalidFiles.length > 1 ? "s are" : " is"} not in a format that can be converted
                  to VRL.
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: 0, display: "inline" }}>
                <div className={classes.scrollList}>
                  <ul>
                    {invalidFiles.map(filename => (
                      <li key={filename}>{filename}</li>
                    ))}
                  </ul>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          {invalidFilesUnsupported.length > 0 && (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<IconExpandMore fontSize="small" />}>
                <div>
                  {invalidFilesUnsupported.length} file
                  {invalidFilesUnsupported.length > 1 ? "s are" : " is"} from an unsupported
                  receiver (HR or Generation 2) and cannot be converted to VRL.
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: 0, display: "inline" }}>
                <div className={classes.scrollList}>
                  <ul>
                    {invalidFilesUnsupported.map(filename => (
                      <li key={filename}>{filename}</li>
                    ))}
                  </ul>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </div>
      </FlexCol>
    </DialogWrapper>
  );
}

FilesExportDialog.propTypes = {
  handleExport: PropTypes.func,
  close: PropTypes.func,
  selection: PropTypes.array,
  fileType: PropTypes.oneOf(["csv", "vrl", ""]),
};

export default withStyles(styles)(FilesExportDialog);
